<template>
  <div class="real-estate-accommodations-table">
    <!-- NEW ACCOMMODATION BUTTON AND SEARCH -->
    <b-row
      v-if="showSearch || showButton"
      class="mb-2 d-flex flex-wrap"
      :class="{
        'justify-content-between': showButton,
        'justify-content-end': !showButton,
      }"
    >
      <b-col v-if="showButton" cols="3" md="auto" class="mb-1 mb-md-0">
        <b-button variant="primary" block @click.prevent="newAccommodation">
          <feather-icon icon="PlusIcon" class="d-block d-md-none" />
          <span class="d-none d-md-inline">{{ $t("Nou allotjament") }}</span>
        </b-button>
      </b-col>
      <b-col
        v-if="showSearch"
        cols="9"
        md="auto"
        class="mb-1 mb-md-0"
        :class="{
          'justify-content-between': showButton,
          'justify-content-end': !showButton,
        }"
      >
        <b-form-input
          id="filterInput"
          v-model="filter"
          type="search"
          :placeholder="`${$t('Cerca')}...`"
          :disabled="loading"
        />
      </b-col>
    </b-row>

    <!-- ACCOMMODATIONS TABLE -->
    <b-row class="mb-2">
      <b-col cols="12">
        <b-table
          striped
          borderless
          hover
          responsive
          class="position-relative"
          :busy="loading"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          sort-icon-left
          :items="accommodations"
          show-empty
          :empty-text="$t('errors.emptyAccommodationsTable.title')"
          :empty-filtered-text="$t('errors.emptyAccommodationsTable.title')"
          :fields="fields"
          :filter="filter"
          @filtered="onFiltered"
          @row-clicked="onRowClicked"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2" />
              <strong>{{ $t("Carregant") }}...</strong>
            </div>
          </template>
          <template #cell(picture)="data">
            <b-avatar :src="data.value" size="100">
              <feather-icon v-if="!data.value" icon="HomeIcon" />
            </b-avatar>
          </template>
          <template #cell(type)="data">
            {{ accommodationTypeName(data.value) }}
          </template>
          <template #cell(area)="data">
            {{ areaName(data.value) }}
          </template>
          <template #cell(price)="data">
            {{ priceText(data.value) }}
          </template>
        </b-table>
      </b-col>
    </b-row>

    <!-- PAGINATION -->
    <b-row
      v-show="!loading"
      v-if="accommodations.length > pageOptions[0]"
      class="d-flex justify-content-between align-items-center"
    >
      <b-col
        cols="12"
        md="4"
        lg="3"
        class="mb-1 mb-md-0 d-flex justify-content-center justify-content-md-start"
      >
        <b-form-group
          id="per-page-input"
          :label="`${$t('Per pàgina')}`"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
        lg="3"
        class="mb-1 mb-md-0 d-flex justify-content-center justify-content-md-end"
      >
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BAvatar,
  BFormGroup,
  BFormSelect,
  BPagination,
  BFormInput,
  BButton,
  BSpinner,
} from "bootstrap-vue";

import { getAccommodationTypeName, getAreaName } from "@/utils/methods";

import { formatCurrency } from "@/utils/formatters";

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BFormInput,
    BButton,
    BSpinner,
  },
  props: {
    accommodations: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    showButton: {
      type: Boolean,
      default: () => false,
    },
    showSearch: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      isBusy: false,
      sortBy: "name",
      sortDesc: false,
      perPage: 10,
      pageOptions: [5, 10, 50, 100],
      totalRows: 1,
      currentPage: 1,
      filter: null,
      fields: [
        { key: "picture", label: this.$t("Foto"), thStyle: { width: "120px" } },
        { key: "reference", label: this.$t("Referència"), sortable: true },
        { key: "type", label: this.$t("Tipus"), sortable: true },
        { key: "location", label: this.$t("Ubicació"), sortable: true },
        { key: "area", label: this.$t("Àrea"), sortable: true },
        { key: "price", label: this.$t("Preu"), sortable: true },
        { key: "trending", label: this.$t("Destacat"), sortable: true },
      ],
    };
  },
  watch: {
    accommodations(accommodations) {
      this.totalRows = accommodations.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.accommodations.length;
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onRowClicked(accommodation) {
      this.$router.push({
        name: "foravila-real-estate-accommodation-view",
        params: {
          accommodationUuid: accommodation.uuid,
        },
      });
    },
    newAccommodation() {
      this.$router.push({
        name: "foravila-new-real-estate-accommodation",
      });
    },
    accommodationTypeName(accommodationTypeCode) {
      return getAccommodationTypeName(accommodationTypeCode, this.$i18n.locale);
    },
    areaName(areaCode) {
      return getAreaName(areaCode);
    },
    priceText(amount) {
      return formatCurrency(amount);
    },
  },
};
</script>

<style lang="scss">
.real-estate-accommodations-table {
  #per-page-input__BV_label_ {
    margin-top: 5px;
  }
}
</style>
