<template>
  <div id="accommodations-list">
    <b-tabs no-fade>
      <b-tab
        :active="draftAccommodations.length > 0"
        :disabled="draftAccommodations.length === 0"
      >
        <template #title>
          <feather-icon icon="AlertCircleIcon" />
          <span>{{ $t("Pendents") }}</span>
        </template>
        <b-card>
          <real-estate-accommodations-table
            :accommodations="draftAccommodations"
            :loading="loadingAccommodations"
            show-button
            show-search
          />
        </b-card>
      </b-tab>

      <b-tab :active="draftAccommodations.length === 0">
        <template #title>
          <feather-icon icon="DollarSignIcon" />
          <span>{{ $t("En venda") }}</span>
        </template>
        <b-card>
          <real-estate-accommodations-table
            :accommodations="onSaleAccommodations"
            :loading="loadingAccommodations"
            show-button
            show-search
          />
        </b-card>
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="ShoppingCartIcon" />
          <span>{{ $t("Venuts") }}</span>
        </template>
        <b-card>
          <real-estate-accommodations-table
            :accommodations="soldAccommodations"
            :loading="loadingAccommodations"
            show-button
            show-search
          />
        </b-card>
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="ShoppingCartIcon" />
          <span>{{ $t("Venuts per 3rs") }}</span>
        </template>
        <b-card>
          <real-estate-accommodations-table
            :accommodations="soldByOthersAccommodations"
            :loading="loadingAccommodations"
            show-button
            show-search
          />
        </b-card>
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="DollarSignIcon" />
          <span>{{ $t("En lloguer") }}</span>
        </template>
        <b-card>
          <real-estate-accommodations-table
            :accommodations="forRentAccommodations"
            :loading="loadingAccommodations"
            show-button
            show-search
          />
        </b-card>
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="ShoppingCartIcon" />
          <span>{{ $t("Llogats") }}</span>
        </template>
        <b-card>
          <real-estate-accommodations-table
            :accommodations="rentedAccommodations"
            :loading="loadingAccommodations"
            show-button
            show-search
          />
        </b-card>
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="ShoppingCartIcon" />
          <span>{{ $t("Llogats per 3rs") }}</span>
        </template>
        <b-card>
          <real-estate-accommodations-table
            :accommodations="rentedByOthersAccommodations"
            :loading="loadingAccommodations"
            show-button
            show-search
          />
        </b-card>
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="SlashIcon" />
          <span>{{ $t("Inactius") }}</span>
        </template>
        <b-card>
          <real-estate-accommodations-table
            :accommodations="disabledAccommodations"
            :loading="loadingAccommodations"
            show-button
            show-search
          />
        </b-card>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import RealEstateAccommodationsTable from "@/views/real-estate-accommodations/list/components/RealEstateAccommodationsTable.vue";
import { getAreaName, notifyError } from "@/utils/methods";
import { BTabs, BTab, BCard } from "bootstrap-vue";

export default {
  name: "RealEstatAccommodationsList",
  components: {
    BCard,
    BTabs,
    BTab,
    RealEstateAccommodationsTable,
  },
  computed: {
    loadingAccommodations() {
      return this.$store.getters[
        "realEstateAccommodations/loadingAccommodations"
      ];
    },
    accommodations() {
      return this.$store.getters["realEstateAccommodations/accommodations"];
    },
    onSaleAccommodations() {
      const accommodations =
        this.$store.getters["realEstateAccommodations/onSaleAccommodations"];
      return this.mapTableAccommodationsArray(accommodations);
    },
    forRentAccommodations() {
      const accommodations =
        this.$store.getters["realEstateAccommodations/forRentAccommodations"];
      return this.mapTableAccommodationsArray(accommodations);
    },
    soldAccommodations() {
      const accommodations =
        this.$store.getters["realEstateAccommodations/soldAccommodations"];
      return this.mapTableAccommodationsArray(accommodations);
    },
    soldByOthersAccommodations() {
      const accommodations =
        this.$store.getters[
          "realEstateAccommodations/soldByOthersAccommodations"
        ];
      return this.mapTableAccommodationsArray(accommodations);
    },
    rentedAccommodations() {
      const accommodations =
        this.$store.getters["realEstateAccommodations/rentedAccommodations"];
      return this.mapTableAccommodationsArray(accommodations);
    },
    rentedByOthersAccommodations() {
      const accommodations =
        this.$store.getters[
          "realEstateAccommodations/rentedByOthersAccommodations"
        ];
      return this.mapTableAccommodationsArray(accommodations);
    },
    disabledAccommodations() {
      const accommodations =
        this.$store.getters["realEstateAccommodations/disabledAccommodations"];
      return this.mapTableAccommodationsArray(accommodations);
    },
    draftAccommodations() {
      const accommodations =
        this.$store.getters["realEstateAccommodations/draftAccommodations"];
      return this.mapTableAccommodationsArray(accommodations);
    },
  },
  created() {
    // Only fetch accommodations if they are not fetched or being fetched
    if (this.accommodations.length < 10 && !this.loadingAccommodations)
      this.fetchAccommodations();
  },
  methods: {
    fetchAccommodations() {
      this.$store
        .dispatch("realEstateAccommodations/fetchAccommodations", {
          pagination: false,
          sort: "asc",
        })
        .catch(() =>
          notifyError(
            this.$t("errors.fetchAccommodations.title"),
            this.$t("errors.fetchAccommodations.description")
          )
        );
    },
    mapTableAccommodationsArray(accommodations) {
      return accommodations.map((accommodation) => ({
        uuid: accommodation.uuid || "No definit",
        picture: accommodation.coverPicture?.thumbnailUrl || null,
        type: accommodation.type || "No definit",
        reference: accommodation.reference || "No definit",
        price: accommodation.price || "No definit",
        location: accommodation.location?.city || "No definit",
        area: getAreaName(accommodation.location?.area) || "No definit",
        trending: accommodation.trending ? "Sí" : "No",
      }));
    },
  },
};
</script>
